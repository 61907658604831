:root
    // --font-family-headings: 'NeutralFace', sans-serif
    --font-family-headings: 'Mariupol Bold', sans-serif
    --font-family-main: 'Mariupol'

    --font-size-xl: 84px
    --line-height-xl: 86px
    // --font-weight-xl: 700

    --font-xl: var(--font-size-xl) / var(--line-height-xl) var(--font-family-headings)

    --font-size-lg: 68px
    --line-height-lg: 70px
    // --font-weight-lg: 700

    --font-lg: var(--font-size-lg) / var(--line-height-lg) var(--font-family-headings)

    --font-size-lg60: 60px
    --line-height-lg60: 66px
    // --font-weight-lg: 700

    --font-lg60: var(--font-size-lg60) / var(--line-height-lg60) var(--font-family-headings)

    --font-size-lg50: 50px
    --line-height-lg50: 54px
    // --font-weight-lg: 700

    --font-lg50: var(--font-size-lg50) / var(--line-height-lg50) var(--font-family-headings)

    --font-size-lg38: 38px
    --line-height-lg38: 40px
    // --font-weight-lg: 700

    --font-lg38: var(--font-size-lg38) / var(--line-height-lg38) var(--font-family-headings)

    --font-size-lg34: 34px
    --line-height-lg34: 38px
    // --font-weight-lg: 700

    --font-lg34: var(--font-size-lg34) / var(--line-height-lg34) var(--font-family-headings)

    --font-size-md28: 28px
    --line-height-md28: 32px
    // --font-weight-lg: 700

    --font-md28: var(--font-size-md28) / var(--line-height-md28) var(--font-family-headings)

    --font-size-md26: 26px
    --line-height-md26: 28px
    // --font-weight-md24: 700

    --font-md26: var(--font-size-md26) / var(--line-height-md26) var(--font-family-main)

    --font-size-md24: 24px
    --line-height-md24: 26px
    // --font-weight-md24: 700

    --font-md24: var(--font-size-md24) / var(--line-height-md24) var(--font-family-main)

    --font-size-md22: 22px
    --line-height-md22: 24px
    // --font-weight-md22: 700

    --font-md22: var(--font-size-md22) / var(--line-height-md22) var(--font-family-main)

    --font-size-md20: 20px
    --line-height-md20: 22px
    // --font-weight-md-btn: 400

    --font-md20: var(--font-size-md20) / var(--line-height-md20) var(--font-family-main)

    --font-size-md18: 18px
    --line-height-md18: 20px
    // --font-weight-md-input: 400

    --font-md19: var(--font-size-md19) / var(--line-height-md19) var(--font-family-main)

    --font-size-md19: 19px
    --line-height-md19: 21px
    // --font-weight-md-input: 400

    --font-md18: var(--font-size-md18) / var(--line-height-md18) var(--font-family-main)

    --font-size-sm: 16px
    --line-height-sm: 18px
    // --font-weight-sm: 400

    --font-md17: var(--font-size-md17) / var(--line-height-md17) var(--font-family-main)

    --font-size-md17: 17px
    --line-height-md17: 19px
    // --font-weight-md17: 400

    --font-sm: var(--font-size-sm) / var(--line-height-sm) var(--font-family-main)

    --font-size-xs: 14px
    --line-height-xs: 16px
    // --font-weight-xs: 400

    --font-xs: var(--font-size-xs) / var(--line-height-xs) var(--font-family-main)

a 
    text-decoration: none

.react-tel-input
    margin-bottom: 15px !important
    & .country-list
        width: 310px !important
        background: var(--secondary-color) !important
        color: #fff !important
        z-index: 99 !important
        & .country
            & .dial-code
                color: var(--lightGray) !important
            & .highlight
                background-color: red !important
            &:hover
                background: gray !important

    & .highlight
        background: #333333 !important
    & .form-control
        border-radius: 0 !important
        height: 42px !important
    & .flag-dropdown 
        background: var(--secondary-color) !important
        border: 0 !important 
        z-index: 99 !important
           
        &:hover
            background: inherit !important
        & .selected-flag
            background: var(--secondary-color) !important
            border: 0 !important