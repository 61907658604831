@import '@styles/mixins.sass'

.container
    position: fixed
    z-index: 9999
    top: 0
    bottom: 0
    left: 0
    right: 0
    background-color: var(--bg-color)
    display: flex
    align-items: center
    justify-content: center

.centerContainer
    display: flex
    align-items: center
    justify-content: flex-end
    width: 550px
    @include for-tablet
        width: 450px
    @include for-tablet-portrait
        width: 350px
    @include for-phone
        width: 265px

.contentContainer
    display: flex
    align-items: center

.logoContainer
    background-color: var(--bg-color)
    div
        min-height: 100px
        min-width: 100px
        display: flex
        align-items: center
        justify-content: center
        @include for-tablet
            min-height: 85px
            min-width: 85px
        @include for-tablet-portrait
            min-height: 80px
            min-width: 80px
        @include for-phone
            min-height: 65px
            min-width: 65px
        svg
            width: 85%
            height: 85%

.perevozkaContainer
    overflow: hidden
    animation: textAppearing 1.7s ease-in-out infinite alternate
    animation-delay: 0.5s
    @include for-tablet
        animation: textAppearingTablet 1.7s ease-in-out infinite alternate
    @include for-tablet-portrait
        animation: textAppearingTabletPortrait 1.7s ease-in-out infinite alternate
    @include for-phone
        animation: textAppearingPhone 1.7s ease-in-out infinite alternate
    h1
        color: var(--text-color)
        font-size: var(--font-size-xl)
        font-weight: 900
        text-align: center
        width: max-content
        float: right
        @include for-tablet
            font-size: var(--font-size-lg)
        @include for-tablet-portrait
            font-size: var(--font-size-lg50)
        @include for-phone
            font-size: var(--font-size-lg38)

@keyframes textAppearing
    0%
        max-width: 550px
    85%
        max-width: 0
    100%
        max-width: 0

@keyframes textAppearingTablet
    0%
        max-width: 450px
    85%
        max-width: 0
    100%
        max-width: 0

@keyframes textAppearingTabletPortrait
    0%
        max-width: 350px
    85%
        max-width: 0
    100%
        max-width: 0

@keyframes textAppearingPhone
    0%
        max-width: 265px
    85%
        max-width: 0
    100%
        max-width: 0
