@font-face
    font-family: 'Mariupol Medium' //not a mistake
    font-display: swap
    src: url(./fonts/Mariupol/Mariupol-Bold.eot) format('embedded-opentype')
    src: url(./fonts/Mariupol/Mariupol-Bold.ttf) format('truetype')
    src: url(./fonts/Mariupol/Mariupol-Bold.woff) format('woff')
    src: url(./fonts/Mariupol/Mariupol-Bold.woff2) format('woff2')
    font-weight: normal
    font-style: normal

@font-face
    font-family: 'Mariupol Bold' //not a mistake
    font-display: swap
    src: url(./fonts/Mariupol/Mariupol-Medium.eot) format('embedded-opentype')
    src: url(./fonts/Mariupol/Mariupol-Medium.ttf) format('truetype')
    src: url(./fonts/Mariupol/Mariupol-Medium.woff) format('woff')
    src: url(./fonts/Mariupol/Mariupol-Medium.woff2) format('woff2')
    font-weight: normal
    font-style: normal

@font-face
    font-family: 'Mariupol'
    font-display: swap
    src: url(./fonts/Mariupol/Mariupol-Regular.eot) format('embedded-opentype')
    src: url(./fonts/Mariupol/Mariupol-Regular.ttf) format('truetype')
    src: url(./fonts/Mariupol/Mariupol-Regular.woff) format('woff')
    src: url(./fonts/Mariupol/Mariupol-Regular.woff2) format('woff2')
    font-weight: normal
    font-style: normal

@font-face
    font-family: 'Mariupol Symbols'
    font-display: swap
    src: url(./fonts/Mariupol/MariupolSymbols.eot) format('embedded-opentype')
    src: url(./fonts/Mariupol/MariupolSymbols.ttf) format('truetype')
    src: url(./fonts/Mariupol/MariupolSymbols.woff) format('woff')
    src: url(./fonts/Mariupol/MariupolSymbols.woff2) format('woff2')
    font-weight: normal
    font-style: normal

@font-face
    font-family: 'NeutralFace'
    font-display: swap
    src: url(./fonts/NeutralFace/NeutralFace-Bold.otf) format('opentype')
    src: url(./fonts/NeutralFace/NeutralFace.otf) format('opentype')
